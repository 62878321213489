.employee-management-content-container {
    background-color: #FBEFE0;
    border-radius: 10px;
    max-height: 70vh;
    overflow-y: auto;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none;  /* For Internet Explorer and Edge */
}

.employee-management-content-container::-webkit-scrollbar {
    display: none;  /* For Chrome, Safari, and Opera */
}

.employee-management-content-container .row {
    padding: 30px;
}

.employee-management-content-container h2 {
    color: #004A78;
}

.content-subheading {
    color: #004A78;
    font-weight: bold;
}

.employee-management-content-container p {
    margin-bottom: 20px;
    font-weight: 600;
}

.content__text__section {
    display: flex;
    flex-direction: column;
    justify-content: center;
}