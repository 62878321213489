.thank-you-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f9f9f9;
  }
  
  .thank-you-card {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    padding: 40px;
    text-align: center;
    max-width: 400px;
  }


  
  .thank-you-title {
    font-size: 2.5rem;
    color: #004A78;
    margin-bottom: 20px;
  }
  
  .thank-you-message {
    font-size: 1.2rem;
    color: #333333;
    margin-bottom: 10px;
  }
  
  .thank-you-submessage {
    font-size: 1rem;
    color: #777777;
    margin-bottom: 30px;
  }
  
  .thank-you-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1rem;
    color: #ffffff;
    background-color: #004A78;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s;
  }
  
  .thank-you-button:hover {
    background-color: #00345a;
  }
  