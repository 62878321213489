.policy-box {
    background-color: white;
    padding: 2.5rem 5rem;
    border-radius: 0.5rem;
    /* box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05); */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 100%;
    overflow-y: auto;
}

/* Decrease padding for medium screens */
@media (max-width: 1024px) {
    .policy-box {
        padding: 2.5rem 3rem;
    }
}

/* Decrease padding for small screens */
@media (max-width: 768px) {
    .policy-box {
        padding: 2.5rem 2rem;
    }
}

/* Decrease padding for extra small screens */
@media (max-width: 480px) {
    .policy-box {
        padding: 2.5rem 1rem;
    }
}

.heading {
    color: #00304E;
    font-weight: bold;
    margin-bottom: 3rem;
    text-align: center;
}

.sub-heading {
    color: #00304E;
    font-weight: 700;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

ul {
    list-style-type: disc;
    padding-left: 1rem;
}

p, ul {
    margin-bottom: 1rem;
}

.policy__para {
    font-size: 22px;
}

.policy__list {
    font-size: 22px;
}

.sec__head__cancellation {
    /* display: inline-block; */
    position: relative;
    text-transform: uppercase;
  }

.sec__head__cancellation::after{
    content: '';
    position: absolute;
    left: 25%;
    bottom: -10px;
    transform: translateX(-50%);
    width: 12%;
    height: 4px;
    background-color: #30B0FF;
}
