/* Carousel Custom Styles */
.carousel-item {
    text-align: center;
    padding: 20px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    background-color: #004A78;
    border-radius: 50%;
    padding: 10px;
}




.product_card_freetrial .members {
    font-size: 1rem;
    margin-bottom: 10px;
}



.product_card_freetrial .freetrial_lineheight li {
    font-size: 0.95rem;
    margin-bottom: 8px;
}

/* Additional Styling */
.left_section {
    background-color: #004A78;
    border-radius: 10px;
}

.title_freetrial {
    font-size: 1.75rem;
    color: #FFFFFF;
}

.col_freetrial {
    margin: 0 auto;
    padding: 15px;
    width: 339px;
}
