.attendance-container {
    background-color: #00aee5;
    padding: 40px 40px 0px 40px;
    border-radius: 10px;
}

.text__section {
    color: white;
}

.btn-container {
    padding: 0.5rem;
}

.attendance-button {
    margin-bottom: 10px;
    background-color: #f9f9f9;
    color: #333;
    border: none;
    background: linear-gradient(#FFFFFF 10%, #C5F1FF 67%);

}

.attendance-button:hover {
    background-color: #e8e8e8;
}

.image-section {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

/* .image-section .showcase-img {
    max-width: 75%;
    height: auto;
    object-fit: contain;
} */


.image-section .showcase-img {
    width: 100%; /* Responsive width */
    height: auto; /* Maintain aspect ratio */
    max-width: 400px; /* Set a maximum width */
    object-fit: contain;
  }

.attendance-button-group .attendance-button {
    font-weight: 600;
    width: 75%;
    display: flex;
    gap: 7%;
    padding: 10px 13px;
    color: #004D65;
}